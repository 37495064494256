
import {
  computed, defineComponent,
} from 'vue';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';

export default defineComponent({
  name: 'SelecionarTipoAnuncioMarketPlace',
  components: {
  },
  props: {
    valor: {
      type: String,
      required: true,
    },
    tipoIntegracao: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: string) => {
        emit('update:valor', valor);
      },
    });

    function change() {
      emit('change');
    }

    return {
      props,
      ETipoIntegracao,
      computedValor,
      change,
    };
  },
});
