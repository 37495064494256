import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change())),
      class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
      "data-coluna": _ctx.props.dataAttributeColuna,
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        (_ctx.props.tipoIntegracao === _ctx.ETipoIntegracao.MercadoLivre)
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 0,
              value: 'gold_special'
            }, {
              default: _withCtx(() => [
                _createTextVNode("Clássico")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.props.tipoIntegracao === _ctx.ETipoIntegracao.MercadoLivre)
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 1,
              value: 'gold_pro'
            }, {
              default: _withCtx(() => [
                _createTextVNode("Premium")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["value", "class", "data-coluna", "disabled"])
  ]))
}