import ApiSistema from '@/core/conectores/ApiSistema';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ICaracteristica } from '@/models/Entidades/Cadastros/ICaracteristica';

/**
 * Serviço de Características
 * Fornece todas regras de negócios e lógicas relacionado a Características.
 */

class ServicoCaracteristica implements IServicoBase<ICaracteristica> {
    endPoint = 'caracteristicas';

    apiSistema = new ApiSistema();

    public requisicaoSistema(): void {
        this.apiSistema = new ApiSistema(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
        const listaPropriedades = await this.apiSistema.obterPropriedadesConsulta(this.endPoint);
        return listaPropriedades;
    }

    public async obterTipoCaracteristica(codigoCaracteristica: number): Promise<number> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/${codigoCaracteristica}/tipo`);
        return result.data;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
        return this.apiSistema.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
        return this.apiSistema.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: ICaracteristica): string {
        return objeto.nome;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apenasParaVariacoes = false): Promise<IItemConsultaRapida[]> {
        console.log('chegou', apenasParaVariacoes);
        let parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
        if (apenasParaVariacoes) {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += 'apenasParaVariacoes=true';
        }
        const result: any = await this.apiSistema.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
        return result.data;
    }

    public async consultaRapidaItens(codigoCaracteristica: number, parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
        const parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
        const result: any = await this.apiSistema.get(`${this.endPoint}/${codigoCaracteristica}/itens/consulta-rapida${parametrosAdicionais}`);
        return result.data;
    }

    public async incluir(marca: ICaracteristica): Promise<IRetornoRequisicao> {
        const result: any = await this.apiSistema.post(`${this.endPoint}`, marca);
        return result.data;
    }

    public async alterar(marca: ICaracteristica): Promise<IRetornoRequisicao> {
        const result: any = await this.apiSistema.put(`${this.endPoint}/${marca.codigo}`, marca);
        return result.data;
    }

    public async obter(codigo: number): Promise<ICaracteristica> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}`);
        return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
        const result: any = await this.apiSistema.delete(`${this.endPoint}/${codigo}`);
        return result.data;
    }

    public async obterTodasCaracteristicas(): Promise<ICaracteristica[]> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/todas`);
        return result.data;
    }
}
export default ServicoCaracteristica;
