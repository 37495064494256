
import {
    defineComponent, onBeforeMount, reactive, computed,
} from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ICampo } from '@/models/Integracoes/Campos/ICampo';
import { IOptionString } from '@/core/models/AntDesign/IOption';

export default defineComponent({
    name: 'AnuncioCampoSelecionar',
    props: {
        campo: {
            type: Object as () => ICampo,
            required: true,
        },
        valor: {
            type: String,
            default: '',
        },
        itensSelecionados: {
            type: Array as () => string[],
            default: [] as string[],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        classCss: {
            type: String,
            default: '',
        },
        dataAttributeColuna: {
            type: String,
            default: '',
        },
    },
    components: {
    },
    emits: ['update:valor', 'update:itensSelecionados'],
    setup(props, { emit }) {
        const computedValor = computed({
            get: () => props.valor,
            set: (valor: string) => {
                emit('update:valor', valor);
            },
        });

        const computedItensSelecionados = computed({
            get: () => props.itensSelecionados,
            set: (valor: string[]) => {
                emit('update:itensSelecionados', valor);
            },
        });

        const state = reactive({
            opcoes: [] as IOptionString[],
        });

        function pesquisarValor(valor: string) {
            state.opcoes = [];

            if (UtilitarioGeral.valorValido(valor)) {
                const itensEncontrados = props.campo.itens.filter((item) => item.valor.toLowerCase().includes(valor.toLowerCase()));

                if (UtilitarioGeral.validaLista(itensEncontrados)) {
                    state.opcoes = itensEncontrados.map((item) => ({
                        value: item.identificador,
                        label: item.valor,
                    }));
                }
            } else {
                state.opcoes = props.campo.itens.map((item) => ({
                    value: item.identificador,
                    label: item.valor,
                }));
            }
        }

        onBeforeMount(() => {
            state.opcoes = props.campo.itens.map((item) => ({
                value: item.identificador,
                label: item.valor,
            }));
        });
        return {
            props,
            state,
            computedValor,
            computedItensSelecionados,
            UtilitarioGeral,
            pesquisarValor,
        };
    },
});
