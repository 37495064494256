import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"margin-left":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedCodigoSelecionado,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigoSelecionado) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change($event))),
      class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
      "data-coluna": _ctx.props.dataAttributeColuna,
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.integracoes, (integracao) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            value: integracao.codigo,
            key: integracao.codigo
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "ss-imagem-responssiva",
                style: {"max-height":"20px"},
                src: _ctx.servicoIntegracao.obtemLogoIntegracao(integracao.tipo)
              }, null, 8, _hoisted_1),
              _createElementVNode("span", _hoisted_2, _toDisplayString(integracao.nome), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "class", "data-coluna", "disabled"])
  ]))
}