
import {
    defineComponent, onBeforeMount, reactive, computed,
} from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ICampo } from '@/models/Integracoes/Campos/ICampo';

export interface IValueAutoComplete {
    value: string;
}
export default defineComponent({
    name: 'AnuncioCampoAutoComplete',
    props: {
        campo: {
            type: Object as () => ICampo,
            required: true,
        },
        valor: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        classCss: {
            type: String,
            default: '',
        },
        dataAttributeColuna: {
            type: String,
            default: '',
        },
    },
    components: {
    },
    emits: ['update:valor'],
    setup(props, { emit }) {
        const computedValor = computed({
            get: () => props.valor,
            set: (valor: string) => {
                emit('update:valor', valor);
            },
        });

        const state = reactive({
            opcoes: [] as IValueAutoComplete[],
        });

        function pesquisarValor(valor: string) {
            state.opcoes = [];

            if (UtilitarioGeral.valorValido(valor)) {
                const itensEncontrados = props.campo.itens.filter((item) => item.valor.toLowerCase().includes(valor.toLowerCase()));

                if (UtilitarioGeral.validaLista(itensEncontrados)) {
                    state.opcoes = itensEncontrados.map((item) => ({
                        value: item.valor,
                    }));
                }
            } else {
                state.opcoes = props.campo.itens.map((item) => ({
                    value: item.valor,
                }));
            }
        }

        onBeforeMount(() => {
            state.opcoes = props.campo.itens.map((item) => ({
                value: item.valor,
            }));
        });
        return {
            props,
            state,
            computedValor,
            UtilitarioGeral,
            pesquisarValor,
        };
    },
});
