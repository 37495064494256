import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ant-row"
}
const _hoisted_2 = {
  key: 0,
  class: "ant-row"
}
const _hoisted_3 = { class: "ant-col ant-col-xs-16 ant-col-xl-16" }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_6 = { class: "ant-col ant-col-xs-8 ant-col-xl-8" }
const _hoisted_7 = ["title"]
const _hoisted_8 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_9 = ["onUpdate:modelValue", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_anuncio_campo_auto_complete = _resolveComponent("anuncio-campo-auto-complete")!
  const _component_anuncio_campo_selecionar = _resolveComponent("anuncio-campo-selecionar")!

  return (_ctx.UtilitarioGeral.validaLista(_ctx.computedCampos))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedCampos, (campo) => {
          return (_openBlock(), _createElementBlock("div", {
            key: campo.identificador,
            class: _normalizeClass(_ctx.obtemClassCampo(campo))
          }, [
            (campo.componente === _ctx.EComponente.NumeroInteiroComUnidade)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_form_item, {
                      class: "ss-margin-campos",
                      rules: [{ required: campo.obrigatorio }]
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("label", {
                          class: "",
                          title: campo.nome
                        }, _toDisplayString(campo.nome) + " " + _toDisplayString(_ctx.props.complementoTitulo), 9, _hoisted_4),
                        (_ctx.UtilitarioGeral.valorValido(campo.dica))
                          ? (_openBlock(), _createBlock(_component_a_tooltip, {
                              key: 0,
                              placement: "top",
                              title: campo.dica
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_icone, {
                                  class: "ss-icone-informacao-campo",
                                  nome: "informacao-campo"
                                })
                              ]),
                              _: 2
                            }, 1032, ["title"]))
                          : _createCommentVNode("", true)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          onKeypress: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validarInformacaoDigitada($event))),
                          onPaste: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validarInformacaoColada($event))),
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": ($event: any) => ((campo.valor) = $event),
                          placeholder: campo.placeholder
                        }, null, 40, _hoisted_5), [
                          [_vModelText, campo.valor]
                        ])
                      ]),
                      _: 2
                    }, 1032, ["rules"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_a_form_item, {
                      label: "Unidade",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: campo.unidadeSelecionada,
                          "onUpdate:value": ($event: any) => ((campo.unidadeSelecionada) = $event),
                          class: "ss-tamanho-completo",
                          style: {"margin-top":"-1px"}
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(campo.itens, (item) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: 'UND' + item.identificador,
                                title: item.valor,
                                value: item.identificador
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.valor), 1)
                                ]),
                                _: 2
                              }, 1032, ["title", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["value", "onUpdate:value"])
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]))
              : (_openBlock(), _createBlock(_component_a_form_item, {
                  key: 1,
                  class: "ss-margin-campos",
                  rules: [{ required: campo.obrigatorio }]
                }, {
                  label: _withCtx(() => [
                    _createElementVNode("label", {
                      class: "",
                      title: campo.nome
                    }, _toDisplayString(campo.nome) + " " + _toDisplayString(_ctx.props.complementoTitulo), 9, _hoisted_7),
                    (_ctx.UtilitarioGeral.valorValido(campo.dica))
                      ? (_openBlock(), _createBlock(_component_a_tooltip, {
                          key: 0,
                          placement: "top",
                          title: campo.dica
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icone, {
                              class: "ss-icone-informacao-campo",
                              nome: "informacao-campo"
                            })
                          ]),
                          _: 2
                        }, 1032, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    (campo.componente === _ctx.EComponente.Texto)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": ($event: any) => ((campo.valor) = $event),
                          placeholder: campo.placeholder
                        }, null, 8, _hoisted_8)), [
                          [_vModelText, campo.valor]
                        ])
                      : (campo.componente === _ctx.EComponente.NumeroInteiro)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 1,
                            type: "text",
                            onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validarInformacaoDigitada($event))),
                            onPaste: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validarInformacaoColada($event))),
                            class: "ant-input",
                            "onUpdate:modelValue": ($event: any) => ((campo.valor) = $event),
                            placeholder: campo.placeholder
                          }, null, 40, _hoisted_9)), [
                            [_vModelText, campo.valor]
                          ])
                        : ((campo.componente === _ctx.EComponente.ListaItens && campo.personalizarValor && !campo.multiValores))
                          ? (_openBlock(), _createBlock(_component_anuncio_campo_auto_complete, {
                              key: 2,
                              valor: campo.valor,
                              "onUpdate:valor": ($event: any) => ((campo.valor) = $event),
                              campo: campo
                            }, null, 8, ["valor", "onUpdate:valor", "campo"]))
                          : ((campo.componente === _ctx.EComponente.ListaItens && campo.personalizarValor && campo.multiValores))
                            ? (_openBlock(), _createBlock(_component_a_select, {
                                key: 3,
                                value: campo.itensSelecionados,
                                "onUpdate:value": ($event: any) => ((campo.itensSelecionados) = $event),
                                mode: "tags",
                                optionFilterProp: "label",
                                "not-found-content": null,
                                class: "ss-tamanho-completo",
                                allowClear: campo.multiValores,
                                placeholder: campo.placeholder
                              }, null, 8, ["value", "onUpdate:value", "allowClear", "placeholder"]))
                            : ((campo.componente === _ctx.EComponente.ListaItens && !campo.personalizarValor))
                              ? (_openBlock(), _createBlock(_component_anuncio_campo_selecionar, {
                                  key: 4,
                                  campo: campo,
                                  valor: campo.valor,
                                  "onUpdate:valor": ($event: any) => ((campo.valor) = $event),
                                  itensSelecionados: campo.itensSelecionados,
                                  "onUpdate:itensSelecionados": ($event: any) => ((campo.itensSelecionados) = $event)
                                }, null, 8, ["campo", "valor", "onUpdate:valor", "itensSelecionados", "onUpdate:itensSelecionados"]))
                              : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["rules"]))
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}