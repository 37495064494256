
import {
  watch, defineComponent, onBeforeMount, reactive, computed,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarTipoAnuncioMarketPlace from '@/components/MarketPlaces/SelecionarTipoAnuncioMarketPlace.vue';
import { ERegraQuantidadeAnunciada } from '@/models/Enumeradores/ERegraQuantidadeAnunciada';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import { IIntegracao } from '@/models/Entidades/Integracao';
import SelecionarCategoriaMarketPlace from '@/components/MarketPlaces/SelecionarCategoriaMarketPlace.vue';
import SelecionarIntegracao from '@/components/Integracoes/SelecionarIntegracao.vue';
import AnuncioCampos from '@/components/Anuncios/AnuncioCampos.vue';
import ServicoAnuncio from '@/servicos/ServicoAnuncio';
import ServicoProduto from '@/servicos/Cadastros/ServicoProduto';
import ServicoMarca from '@/servicos/Cadastros/ServicoMarca';
import ServicoCaracteristica from '@/servicos/Cadastros/ServicoCaracteristica';
import { EComponente } from '@/models/Enumeradores/EComponente';
import { IAnuncio, IAnuncioVariacao } from '@/models/Entidades/IAnuncio';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';
import { EStatusAnuncio } from '@/models/Enumeradores/EStatusAnuncio';
import { IProduto } from '@/models/Entidades/Cadastros/IProduto';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useModalBase } from '@/core/composables/ModalBase';
import { ICampo } from '@/models/Integracoes/Campos/ICampo';
import { ETipoProduto } from '@/models/Enumeradores/ETipoProduto';
import { ICaracteristica } from '@/models/Entidades/Cadastros/ICaracteristica';
import { IRetornoAnuncioMarketPlace } from '@/models/Retornos/IRetornoAnuncioMarketPlace';
import { IDTOAtualizarAnuncioStatus } from '@/models/DTO/Anuncios/IDTOAtualizarAnuncioStatus';

export default defineComponent({
  name: 'AnuncioDrawer',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    codigoAnuncio: {
      type: Number,
      default: 0,
    },
    codigoProduto: {
      type: Number,
      required: true,
    },
    produto: {
      type: Object as () => IProduto,
      default: {} as IProduto,
    },
  },
  components: {
    Card,
    Icone,
    CampoNumerico,
    RequisicaoModal,
    SelecionarIntegracao,
    SelecionarTipoAnuncioMarketPlace,
    SelecionarCategoriaMarketPlace,
    AnuncioCampos,
  },
  emits: ['update:visivel'],
  setup(props, { emit }) {
    const { apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const servicoIntegracao = new ServicoIntegracao();
    const servicoAnuncio = new ServicoAnuncio();
    const servicoProduto = new ServicoProduto();
    const servicoMarca = new ServicoMarca();

    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);

    const state = reactive({
      carregando: false,
      produtoComVariacao: false,
      integracoes: [] as IIntegracao[],
      caracteristicasCadastradas: [] as ICaracteristica[],
      anuncio: {} as IAnuncio,
      tipoIntegracaoSelecionada: 0,
      camposObrigatorios: [] as ICampo[],
      camposFichaTecnica: [] as ICampo[],
      camposVariacoes: [] as ICampo[],
      computedVisivel: computed({
      get: () => props.visivel,
      set: (val: boolean) => {
        emit('update:visivel', val);
      },
    }),
    });

    function objetoInicial() {
      state.camposObrigatorios = [];
      state.camposFichaTecnica = [];
      state.camposVariacoes = [];
      state.produtoComVariacao = false;
      state.caracteristicasCadastradas = [];
      state.tipoIntegracaoSelecionada = 0;
      state.anuncio = {} as IAnuncio;
      state.anuncio.codigo = 0;
      state.anuncio.codigoProduto = props.codigoProduto;
      if (props.codigoProduto > 0) {
        if (UtilitarioGeral.objetoValido(props.produto) && props.produto.codigo > 0) {
          state.anuncio.titulo = props.produto.nome;
          state.anuncio.descricao = props.produto.descricao;
          state.anuncio.preco = props.produto.definicoes[0].precoVenda;
          state.anuncio.produto = props.produto;
          state.produtoComVariacao = (props.produto.tipo === ETipoProduto.ProdutoComVariacao);
        }
      }
      state.anuncio.codigoIntegracao = state.integracoes[0].codigo;
      state.tipoIntegracaoSelecionada = state.integracoes[0].tipo;
      state.anuncio.tipo = '';
      if (state.tipoIntegracaoSelecionada === ETipoIntegracao.MercadoLivre) {
        state.anuncio.tipo = 'gold_special';
      }
      state.anuncio.regraQuantidadeAnunciada = ERegraQuantidadeAnunciada.Padrao;
      state.anuncio.quantidadeAnunciada = 1;
      state.anuncio.freteGratis = false;
      state.anuncio.status = EStatusAnuncio.EmCriacao;
      state.anuncio.campos = [];
      state.anuncio.variacoes = [];
    }

    function cliqueRegraQuantidadeAnunciada() {
      if (state.anuncio.regraQuantidadeAnunciada === ERegraQuantidadeAnunciada.Padrao) {
        state.anuncio.regraQuantidadeAnunciada = ERegraQuantidadeAnunciada.KITConjunto;
        state.anuncio.quantidadeAnunciada = 1;
      } else {
        state.anuncio.regraQuantidadeAnunciada = ERegraQuantidadeAnunciada.Padrao;
        state.anuncio.quantidadeAnunciada = 1;
      }
    }

    onBeforeMount(async () => {
      state.integracoes = await servicoIntegracao.obterIntegracoes();
      state.integracoes = state.integracoes.filter((c) => c.tipo !== ETipoIntegracao.AnyMarket);
    });

    function prepararCamposAnuncio(campos: ICampo[]) {
        state.camposObrigatorios = [];
        state.camposFichaTecnica = [];
        state.camposVariacoes = [];
        if (UtilitarioGeral.validaLista(campos)) {
          campos.forEach((campo) => {
            if (campo.obrigatorio) {
              state.camposObrigatorios.push(campo);
            } else {
              state.camposFichaTecnica.push(campo);
            }

            if (campo.regrasAdicionais.some((c) => c === 'Variacao')) {
              state.camposVariacoes.push(campo);
            }
          });
        }
    }

    async function verificaPreechimentoCamposAnuncioComDadosProduto() {
      if (UtilitarioGeral.validaLista(state.camposObrigatorios)) {
        const indexMarca = state.camposObrigatorios.findIndex((c) => c.relacionado === 'Marca');
        if (indexMarca >= 0) {
          if (!UtilitarioGeral.valorValido(state.camposObrigatorios[indexMarca].valor)) {
            const marca = await servicoMarca.obter(state.anuncio.produto.codigoMarca);
            state.camposObrigatorios[indexMarca].valor = marca.nome;
          }
        }

        const indexModelo = state.camposObrigatorios.findIndex((c) => c.relacionado === 'Modelo');
        if (indexModelo >= 0) {
          if (!UtilitarioGeral.valorValido(state.camposObrigatorios[indexModelo].valor)) {
            state.camposObrigatorios[indexModelo].valor = state.anuncio.produto.modelo;
          }
        } else {
          const indexModelo = state.camposFichaTecnica.findIndex((c) => c.relacionado === 'Modelo');
          if (indexModelo >= 0) {
            if (!UtilitarioGeral.valorValido(state.camposFichaTecnica[indexModelo].valor)) {
              state.camposFichaTecnica[indexModelo].valor = state.anuncio.produto.modelo;
            }
          }
        }

        const indexCodigoBarras = state.camposObrigatorios.findIndex((c) => c.relacionado === 'CodigoBarras');
        if (indexCodigoBarras >= 0) {
          let codigoBarras = '';

          if (state.anuncio.produto.tipo === ETipoProduto.ProdutoSimples) {
            codigoBarras = state.anuncio.produto.definicoes[0].codigoBarras;
          } else {
            for (let index = 0; index < state.anuncio.produto.definicoes.length; index += 1) {
              if (UtilitarioGeral.valorValido(state.anuncio.produto.definicoes[index].codigoBarras)) {
                codigoBarras = state.anuncio.produto.definicoes[index].codigoBarras;
                break;
              }
            }
          }
          if (UtilitarioGeral.valorValido(codigoBarras)) {
            if (state.camposObrigatorios[indexCodigoBarras].multiValores) {
              if (!UtilitarioGeral.validaLista(state.camposObrigatorios[indexCodigoBarras].itensSelecionados)) {
                state.camposObrigatorios[indexCodigoBarras].itensSelecionados = [];
                state.camposObrigatorios[indexCodigoBarras].itensSelecionados.push(codigoBarras);
              }
            } else if (!UtilitarioGeral.valorValido(state.camposObrigatorios[indexCodigoBarras].valor)) {
                state.camposObrigatorios[indexCodigoBarras].valor = codigoBarras;
            }
          }
        }
      }
    }

    async function buscarCamposCategoria() {
      if (UtilitarioGeral.valorValido(state.anuncio.codigoCategoria)) {
        state.camposObrigatorios = [];
        state.camposFichaTecnica = [];
        state.camposVariacoes = [];
        const camposCategoria = await servicoAnuncio.obterCamposCategoria(state.anuncio.codigoIntegracao, state.anuncio.codigoCategoria);
        prepararCamposAnuncio(camposCategoria);
        if (state.produtoComVariacao) {
          state.anuncio.variacoes = [];
          for (let index = 0; index < state.anuncio.produto.definicoes.length; index += 1) {
            const variacao:IAnuncioVariacao = {} as IAnuncioVariacao;
            variacao.codigo = 0;
            variacao.codigoAnuncio = state.anuncio.codigo;
            variacao.codigoProdutoDefinicao = state.anuncio.produto.definicoes[index].codigo;
            variacao.nome = servicoProduto.montaDescricaoVariacaoProduto(state.anuncio.produto.definicoes[index].caracteristicasVariacao, state.caracteristicasCadastradas);
            variacao.campos = [];
            variacao.camposVariacoes = [];
            state.anuncio.produto.definicoes[index].caracteristicasVariacao.forEach((caracteristicaVariacao) => {
              const caracteristica = state.caracteristicasCadastradas.find((c) => c.codigo === caracteristicaVariacao.codigoCaracteristica);
              if (caracteristica !== undefined) {
                const campoVariacao = UtilitarioGeral.instanciaObjetoLocal(state.camposVariacoes.find((c) => c.nome.toLowerCase() === caracteristica.nome.toLowerCase()));
                if (campoVariacao !== undefined) {
                  const itemCaracteristica = caracteristica.itens.find((c) => c.codigo === caracteristicaVariacao.codigoCaracteristicaItem);
                  if (campoVariacao.personalizarValor && campoVariacao.componente === EComponente.ListaItens && itemCaracteristica !== undefined) {
                    if (campoVariacao.multiValores) {
                      campoVariacao.itensSelecionados = [];
                      campoVariacao.itensSelecionados.push(itemCaracteristica.valor);
                    } else {
                      campoVariacao.valor = itemCaracteristica.valor;
                    }
                  } else if (campoVariacao.personalizarValor && campoVariacao.componente === EComponente.Texto && itemCaracteristica !== undefined) {
                    campoVariacao.valor = itemCaracteristica.valor;
                  }
                  campoVariacao.largura = 100;
                  const indexFicha = state.camposFichaTecnica.findIndex((c) => c.identificador === campoVariacao.identificador);
                  if (indexFicha >= 0) {
                    state.camposFichaTecnica.splice(indexFicha, 1);
                  }
                  variacao.camposVariacoes.push(campoVariacao);
                }
              }
            });

            if (variacao.camposVariacoes.length < state.anuncio.produto.definicoes[index].caracteristicasVariacao.length) {
              for (let indexCampos = 0; index < state.camposVariacoes.length; indexCampos += 1) {
                const campoNaVariacao = variacao.camposVariacoes.find((c) => c.identificador === state.camposVariacoes[indexCampos].identificador);
                if (campoNaVariacao === undefined) {
                  variacao.camposVariacoes.push(state.camposVariacoes[indexCampos]);
                }

                if (variacao.camposVariacoes.length === state.anuncio.produto.definicoes[index].caracteristicasVariacao.length) {
                  break;
                }
              }
            }

            state.anuncio.variacoes.push(variacao);
          }
        }

        await verificaPreechimentoCamposAnuncioComDadosProduto();
      }
    }

    async function salvar() {
      let retorno: IRetornoAnuncioMarketPlace = {} as IRetornoAnuncioMarketPlace;

      if (!UtilitarioGeral.valorValido(state.anuncio.titulo)) {
        apresentarMensagemAlerta('Informe o título do seu anúncio.');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.anuncio.codigoCategoria)) {
        apresentarMensagemAlerta('É necessário selecionar uma categoria do Marketplace.');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.anuncio.descricao)) {
        apresentarMensagemAlerta('Para melhorar a qualidade do seu anúncio é recomendado ter uma descrição mesmo que seja mínima.');
        return;
      }

      if (state.anuncio.preco <= 0) {
        apresentarMensagemAlerta('Informe o preço do produto neste anúncio.');
        return;
      }

      if (state.anuncio.quantidadeAnunciada <= 0) {
        apresentarMensagemAlerta('As quantidade no KIT/Conjunto não podem ser menor que 1.');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.anuncio.tipo)) {
        apresentarMensagemAlerta('É necessário selecionar o tipo do anúncio.');
        return;
      }

      state.anuncio.campos = [];
      let campoNaoPreenchido = false;
      state.camposObrigatorios.forEach((campo) => {
        if (!UtilitarioGeral.valorValido(campo.valor) && !UtilitarioGeral.validaLista(campo.itensSelecionados)) {
          apresentarMensagemAlerta(`É necessário preeencher o campo: ${campo.nome}.`);
          campoNaoPreenchido = true;
        }
        state.anuncio.campos.push(campo);
      });

      if (campoNaoPreenchido) return;

      apresentarBarraProgresso();

      state.camposFichaTecnica.forEach((campo) => {
        state.anuncio.campos.push(campo);
      });

      if (state.anuncio.codigo === 0) {
        retorno = await servicoAnuncio.incluir(state.anuncio);
      } else {
        retorno = await servicoAnuncio.alterar(state.anuncio);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        sincronizarRegistro(retorno.anuncio.codigo);
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);

        if (retorno.anuncio.codigo > 0) {
            modalBase.computedVisivel = false;
            sincronizarRegistro(retorno.anuncio.codigo);
        }
      }
    }

    async function atualizarStatusAnuncio(status: EStatusAnuncio) {
      let retorno: IRetornoAnuncioMarketPlace = {} as IRetornoAnuncioMarketPlace;

      apresentarBarraProgresso();
      const dadosAtualizacao: IDTOAtualizarAnuncioStatus = {} as IDTOAtualizarAnuncioStatus;
      dadosAtualizacao.codigoAnuncio = state.anuncio.codigoAnuncio;
      dadosAtualizacao.codigoIntegracao = state.anuncio.codigoIntegracao;
      dadosAtualizacao.status = status;
      retorno = await servicoAnuncio.atualizarStatusAnuncio(state.anuncio.codigo, dadosAtualizacao);

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        sincronizarRegistro(retorno.anuncio.codigo);
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    async function atualizarImagens() {
      let retorno: IRetornoAnuncioMarketPlace = {} as IRetornoAnuncioMarketPlace;

      apresentarBarraProgresso();
      retorno = await servicoAnuncio.atualizarImagens(state.anuncio);

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        sincronizarRegistro(retorno.anuncio.codigo);
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    watch(async () => state.computedVisivel, async () => {
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (state.produtoComVariacao) {
          state.caracteristicasCadastradas = await new ServicoCaracteristica().obterTodasCaracteristicas();
        }
        if (props.codigoAnuncio > 0) {
          state.carregando = true;
          state.anuncio = await servicoAnuncio.obter(props.codigoAnuncio);
          const integracao = state.integracoes.find((c) => c.codigo === state.anuncio.codigoIntegracao);
          if (integracao !== undefined) {
            state.tipoIntegracaoSelecionada = integracao.tipo;
          }
          if (UtilitarioGeral.valorValido(state.anuncio.codigoAnuncio)) {
            state.camposObrigatorios = [];
            state.camposFichaTecnica = [];
            state.camposVariacoes = [];
            prepararCamposAnuncio(state.anuncio.campos);
          } else {
            await buscarCamposCategoria();
          }
          state.carregando = false;
        }
      }
    });

    function removerVariacao(index: number) {
      state.anuncio.variacoes.splice(index, 1);
    }

    function obterCodigoInternoVariacao(codigoProdutoDefinicao: number): string {
      const produtoDefinicao = state.anuncio.produto.definicoes.find((c) => c.codigo === codigoProdutoDefinicao);
      if (produtoDefinicao !== undefined) {
        return produtoDefinicao.codigoInterno;
      }

      return 'Não Informado';
    }

    function permitirAlterarCamposEspecificosAnuncio() : boolean {
      if (state.anuncio.status !== EStatusAnuncio.EmCriacao) {
        return true;
      }

      if (state.anuncio.codigo > 0 && UtilitarioGeral.valorValido(state.anuncio.codigoAnuncio)) return true;

      return false;
    }
    return {
      props,
      state,
      modalBase,
      UtilitarioGeral,
      EStatusAnuncio,
      ERegraQuantidadeAnunciada,
      EComponente,
      cliqueRegraQuantidadeAnunciada,
      servicoIntegracao,
      buscarCamposCategoria,
      salvar,
      servicoProduto,
      removerVariacao,
      obterCodigoInternoVariacao,
      permitirAlterarCamposEspecificosAnuncio,
      atualizarStatusAnuncio,
      atualizarImagens,
    };
  },
});
