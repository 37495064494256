import ApiSistema from '@/core/conectores/ApiSistema';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IProduto, IProdutoCaracteristica } from '@/models/Entidades/Cadastros/IProduto';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOProduto } from '@/models/DTO/Cadastros/Produtos/IDTOProduto';
import { IParametrosConsultaProdutoDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Produtos/IParametrosConsultaProdutoDefinicao';
import { IDTOProdutoDefinicaoIdentificacao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicaoIdentificacao';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import { IDTOProdutoDefinicaoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicaoMovimento';
import { ICaracteristica } from '@/models/Entidades/Cadastros/ICaracteristica';
import { ETipoCaracteristica } from '@/models/Enumeradores/ETipoCaracteristica';
import { IDTOTornarProdutoVariacao } from '@/models/DTO/Cadastros/Produtos/IDTOTornarProdutoVariacao';

/**
 * Serviço de Produtos
 * Fornece todas regras de negócios e lógicas relacionado a Produtos.
 */

class ServicoProduto implements IServicoBase<IProduto> {
  endPoint = 'produtos';

  apiSistema = new ApiSistema();

  public requisicaoSistema(): void {
    this.apiSistema = new ApiSistema(true);
  }

  public async obterListaTipoProduto(): Promise<IItemGenerico[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/tipos`);
    return result.data;
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiSistema.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async obterPropriedadesConsultaDefinicoes(): Promise<IPropriedadeConsulta[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/definicoes/propriedades-consulta`);
    return result.data;
  }

  public async obterDadosIdentificacaoDefinicao(codigoDefinicao: number): Promise<IDTOProdutoDefinicaoIdentificacao> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/definicoes/${codigoDefinicao}/identificacao`);
    return result.data;
  }

  public async obterDadosDetalhesDefinicao(codigoDefinicao: number): Promise<IDTOProdutoDefinicao> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/definicoes/${codigoDefinicao}/detalhes`);
    return result.data;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiSistema.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async buscaAvancadaDefinicoes(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], parametrosConsultaProdutoDefinicao?: IParametrosConsultaProdutoDefinicao): Promise<IListaPaginada> {
    const parametrosEmpresas = this.apiSistema.obterParametroContratantes(parametrosConsulta.contratantes);
    let parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
    if (parametrosConsultaProdutoDefinicao !== undefined) {
      if (UtilitarioGeral.validaBoleano(parametrosConsultaProdutoDefinicao.apenasAtivos)) {
        parametrosAdicionais += `&apenasAtivos=${parametrosConsultaProdutoDefinicao.apenasAtivos}`;
      }
      if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.ausentesTabelaPreco)) {
        parametrosAdicionais += `&ausentesTabelaPreco=${parametrosConsultaProdutoDefinicao.ausentesTabelaPreco}`;
      }
      if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.presentesTabelaPreco)) {
        parametrosAdicionais += `&presentesTabelaPreco=${parametrosConsultaProdutoDefinicao.presentesTabelaPreco}`;
      }
      if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.ausentesBalancoEstoque)) {
        parametrosAdicionais += `&ausentesBalancoEstoque=${parametrosConsultaProdutoDefinicao.ausentesBalancoEstoque}`;
      }
      if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.presentesBalancoEstoque)) {
        parametrosAdicionais += `&presentesBalancoEstoque=${parametrosConsultaProdutoDefinicao.presentesBalancoEstoque}`;
      }
    }

    const result: any = await this.apiSistema.get(`${this.endPoint}/definicoes${parametrosEmpresas}${parametrosAdicionais}`);
    const listaPaginada = {} as IListaPaginada;
    listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
    listaPaginada.dados = result.data;
    return listaPaginada;
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiSistema.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOProduto): string {
    return objeto.descricao;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigoInterno?: boolean): Promise<IItemConsultaRapida[]> {
    let parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    if (UtilitarioGeral.validaBoleano(apresentarCodigoInterno)) {
      parametrosAdicionais += `&apresentarCodigoInterno=${apresentarCodigoInterno}`;
    }
    const result: any = await this.apiSistema.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async consultaRapidaDefinicoes(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigoInterno?: boolean): Promise<IItemConsultaRapida[]> {
    let parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    if (UtilitarioGeral.validaBoleano(apresentarCodigoInterno)) {
      parametrosAdicionais += `&apresentarCodigoInterno=${apresentarCodigoInterno}`;
    }
    const result: any = await this.apiSistema.get(`${this.endPoint}/definicoes/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async consultaRapidaDefinicoesMovimentos(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigoInterno?: boolean, filtrarVinculoEstoqueEntrada?: boolean, filtrarVinculoEstoqueSaida?: boolean): Promise<IDTOProdutoDefinicaoMovimento[]> {
    let parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    if (UtilitarioGeral.validaBoleano(apresentarCodigoInterno)) {
      parametrosAdicionais += `&apresentarCodigoInterno=${apresentarCodigoInterno}`;
    }
    if (UtilitarioGeral.validaBoleano(filtrarVinculoEstoqueEntrada)) {
      parametrosAdicionais += `&filtrarVinculoEstoqueEntrada=${filtrarVinculoEstoqueEntrada}`;
    }

    if (UtilitarioGeral.validaBoleano(filtrarVinculoEstoqueSaida)) {
      parametrosAdicionais += `&filtrarVinculoEstoqueSaida=${filtrarVinculoEstoqueSaida}`;
    }
    const result: any = await this.apiSistema.get(`${this.endPoint}/definicoes/movimentos/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async incluir(objeto: IProduto): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}`, objeto);
    return result.data;
  }

  public async tornarProdutoComVariacao(objeto: IDTOTornarProdutoVariacao): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/tornar-variacao`, objeto);
    return result.data;
  }

  public async alterar(objeto: IProduto): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.put(`${this.endPoint}/${objeto.codigo}`, objeto);
    return result.data;
  }

  public async obter(codigo: number): Promise<IProduto> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterVarios(codigos: number[]): Promise<IProduto[]> {
    let parametrosAdicionais = '';
    if (UtilitarioGeral.validaLista(codigos)) {
      codigos.forEach((codigo) => {
        if (UtilitarioGeral.validaCodigo(codigo)) {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigos=${codigo}`;
        }
      });
    }

    const result: any = await this.apiSistema.get(`${this.endPoint}/varios${parametrosAdicionais}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async enviarImagens(imagens: any[]): Promise<IRetornoArquivo[]> {
    const data = new FormData();

    for (let i = 0; i < imagens.length; (i += 1)) {
      data.append('arquivos', imagens[i]);
    }
    const result: any = await this.apiSistema.upload(`${this.endPoint}/imagens/enviar`, data);
    return result.data;
  }

  public async removerImagem(codigoProdutoImagem: number, imagem: string): Promise<IRetornoArquivo> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/imagens/${codigoProdutoImagem}`, imagem);
    return result.data;
  }

  public montaDescricaoVariacaoProduto(produtoCaracteristicas: IProdutoCaracteristica[], caracteristicas: ICaracteristica[]):string {
    let descricaoVariacao = '';
    if (UtilitarioGeral.validaLista(produtoCaracteristicas) && UtilitarioGeral.validaLista(caracteristicas)) {
      const caracteristicasComVariacao = produtoCaracteristicas.filter((c) => c.variacao === true);
      if (UtilitarioGeral.validaLista(caracteristicasComVariacao)) {
        caracteristicasComVariacao.forEach((produtoCaracteristica) => {
          const dadosCaracteristica = caracteristicas.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristica);
          if (dadosCaracteristica !== undefined) {
            if (dadosCaracteristica.tipo === ETipoCaracteristica.TextoLivre
              || dadosCaracteristica.tipo === ETipoCaracteristica.Decimal
              || dadosCaracteristica.tipo === ETipoCaracteristica.Numero) {
              descricaoVariacao += ` ${dadosCaracteristica.nome}: ${produtoCaracteristica.caracteristicaPersonalizada}`;
            } else if (dadosCaracteristica.tipo === ETipoCaracteristica.ListaSimples
              || dadosCaracteristica.tipo === ETipoCaracteristica.Cor) {
              const dadosItemCaracteristica = dadosCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
              if (dadosItemCaracteristica !== undefined) {
                descricaoVariacao += ` ${dadosCaracteristica.nome}: ${dadosItemCaracteristica.valor}`;
              }
            }
          }
        });
      }
    }
    return descricaoVariacao;
  }
}
export default ServicoProduto;
