export enum EComponente {
    Texto = 1,
    AreaTexto = 2,
    NumeroInteiro = 3,
    NumeroInteiroComUnidade = 4,
    NumeroReal = 5,
    NumeroPercentual = 6,
    CheckBox = 7,
    RadioBox = 8,
    Switch = 9,
    ListaItens = 10
}
