import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.campo.multiValores ? _ctx.computedItensSelecionados : _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.campo.multiValores ? _ctx.computedItensSelecionados = $event : _ctx.computedValor = $event)),
      optionFilterProp: "label",
      class: "ss-tamanho-completo",
      "show-search": "",
      allowClear: _ctx.campo.multiValores,
      options: _ctx.state.opcoes,
      "not-found-content": null,
      placeholder: _ctx.campo.placeholder,
      onSearch: _ctx.pesquisarValor
    }, null, 8, ["value", "allowClear", "options", "placeholder", "onSearch"])
  ]))
}