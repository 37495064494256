import ApiSistema from '@/core/conectores/ApiSistema';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOAnuncio } from '@/models/DTO/Anuncios/IDTOAnuncio';
import { IDTOAnuncioResumo } from '@/models/DTO/Anuncios/IDTOAnuncioResumo';
import { IDTOAtualizarAnuncioStatus } from '@/models/DTO/Anuncios/IDTOAtualizarAnuncioStatus';
import { IDTOImportarAnuncio } from '@/models/DTO/Anuncios/IDTOImportarAnuncio';
import { IAnuncio } from '@/models/Entidades/IAnuncio';
import { EStatusAnuncio } from '@/models/Enumeradores/EStatusAnuncio';
import { ICampo } from '@/models/Integracoes/Campos/ICampo';
import { IConsultaMarketPlace } from '@/models/Integracoes/IConsultaMarketPlace';
import { IParametrosConsultaAnuncio } from '@/models/ParametrosRequisicao/IParametrosConsultaAnuncio';
import { IRetornoAnuncioMarketPlace } from '@/models/Retornos/IRetornoAnuncioMarketPlace';

/**
 * Serviço de Anúncios
 * Fornece todas regras de negócios e lógicas relacionado aos anúncios.
 */

class ServicoAnuncio {
    private endPoint = 'anuncios';

    private apiSistema = new ApiSistema();

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
        const listaPropriedades = await this.apiSistema.obterPropriedadesConsulta(this.endPoint);
        return listaPropriedades;
    }

    public async incluir(anuncio: IAnuncio): Promise<IRetornoAnuncioMarketPlace> {
        const result: any = await this.apiSistema.post(`${this.endPoint}`, anuncio);
        return result.data;
    }

    public async alterar(anuncio: IAnuncio): Promise<IRetornoAnuncioMarketPlace> {
        const result: any = await this.apiSistema.put(`${this.endPoint}/${anuncio.codigo}`, anuncio);
        return result.data;
    }

    public async atualizarStatusAnuncio(codigo: number, dadosAtualizacao: IDTOAtualizarAnuncioStatus): Promise<IRetornoAnuncioMarketPlace> {
        const result: any = await this.apiSistema.patch(`${this.endPoint}/${codigo}/status`, dadosAtualizacao);
        return result.data;
    }

    public async atualizarImagens(anuncio: IAnuncio): Promise<IRetornoAnuncioMarketPlace> {
        const result: any = await this.apiSistema.post(`${this.endPoint}/${anuncio.codigo}/imagens`, anuncio);
        return result.data;
    }

    public async obter(codigo: number): Promise<IAnuncio> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}`);
        return result.data;
    }

    public async obterResumoAnuncios(): Promise<IDTOAnuncioResumo[]> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/resumo`);
        return result.data;
    }

    public async obterAnuncios(parametrosConsulta: IParametrosConsultaAnuncio, filtros?: IFiltroGenerico[]): Promise<IDTOAnuncio[]> {
        let parametrosAdicionais = '';
        if (UtilitarioGeral.validaCodigo(parametrosConsulta.integracao)) {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `Integracao=${parametrosConsulta.integracao}`;
        }

        if (UtilitarioGeral.validaCodigo(parametrosConsulta.produto)) {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `Produto=${parametrosConsulta.produto}`;
        }

        if (filtros !== undefined) {
            const jsonFiltros = JSON.stringify(filtros);
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `Filtros=${jsonFiltros}`;
        }
        if (parametrosConsulta.ordenacao !== undefined) {
            parametrosConsulta.ordenacao.forEach((item) => {
                parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
                parametrosAdicionais += `Ordenacao=${item}`;
            });
        }
        if (parametrosConsulta.codigosSelecionados !== undefined) {
            parametrosConsulta.codigosSelecionados.forEach((codigo) => {
                parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
                parametrosAdicionais += `CodigosSelecionados=${codigo}`;
            });
        }

        if (UtilitarioGeral.valorValido(parametrosConsulta.buscaRapida)) {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `buscaRapida=${parametrosConsulta.buscaRapida}`;
        }

        const result: any = await this.apiSistema.get(`${this.endPoint}${parametrosAdicionais}`);
        return result.data;
    }

    public async consultaCategoria(codigoIntegracao: number, valor: string, filtrarPeloIdentificador = false): Promise<IConsultaMarketPlace[]> {
        let parametrosAdicionais = '';
        if (filtrarPeloIdentificador === true) {
            parametrosAdicionais += '&filtrarPeloIdentificador=true';
        }
        const result: any = await this.apiSistema.get(`${this.endPoint}/integracao/${codigoIntegracao}/categorias/consulta?valor=${valor}${parametrosAdicionais}`);
        return result.data;
    }

    public async obterCamposCategoria(codigoIntegracao: number, identificadorCategoria: string): Promise<ICampo[]> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/integracao/${codigoIntegracao}/categorias/${identificadorCategoria}/campos`);
        return result.data;
    }

    public async importarAnuncios(dadosImportacao: IDTOImportarAnuncio): Promise<IRetornoRequisicao> {
        const result: any = await this.apiSistema.post(`${this.endPoint}/importar`, dadosImportacao);
        return result.data;
    }

    public async obterAnunciosProduto(codigoProduto: number): Promise<IAnuncio[]> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/produtos/${codigoProduto}`);
        return result.data;
    }

    public obtemDescricaoStatus(tipo: EStatusAnuncio): string {
        let descricao = '';
        switch (tipo) {
            case EStatusAnuncio.EmCriacao:
                descricao = 'Em criação';
                break;

            case EStatusAnuncio.Ativo:
                descricao = 'Ativo';
                break;

            case EStatusAnuncio.Pausado:
                descricao = 'Pausado';
                break;

            case EStatusAnuncio.Reprovado:
                descricao = 'Reprovado';
                break;

            default:
                break;
        }

        return descricao;
    }
}
export default ServicoAnuncio;
