
import {
    defineComponent, onBeforeMount,
    reactive, computed,
} from 'vue';
import { IIntegracao } from '@/models/Entidades/Integracao';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';

export default defineComponent({
    name: 'SelecionarIntegracao',
    components: {
    },
    props: {
        codigoSelecionado: {
            type: Number || undefined,
        },
        tipoIntegracao: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        classCss: {
            type: String,
            default: '',
        },
        dataAttributeColuna: {
            type: String,
            default: '',
        },
        apenasMarketPlaces: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:codigoSelecionado', 'update:tipoIntegracao', 'change'],
    setup(props, { emit }) {
        const servicoIntegracao = new ServicoIntegracao();

        const state = reactive({
            integracoes: [] as IIntegracao[],
        });

        function obterIntegracao(): any {
            if (props.codigoSelecionado === 0) {
                return undefined;
            }
            return props.codigoSelecionado;
        }

        const computedCodigoSelecionado = computed({
            get: () => obterIntegracao(),
            set: (codigoSelecionado: number) => {
                emit('update:codigoSelecionado', codigoSelecionado);
            },
        });

        function change(codigoSelecionado: number) {
            const integracao = state.integracoes.find((c) => c.codigo === codigoSelecionado);
            if (integracao !== undefined) {
                emit('update:tipoIntegracao', integracao.tipo);
            }
            emit('update:codigoSelecionado', codigoSelecionado);
            emit('change', codigoSelecionado);
        }

        onBeforeMount(async () => {
            state.integracoes = [];
            let integracoes = await servicoIntegracao.obterIntegracoes();
            if (props.apenasMarketPlaces) {
                integracoes = integracoes.filter((c) => c.tipo !== ETipoIntegracao.AnyMarket);
            }

            state.integracoes = integracoes;
        });

        return {
            props,
            state,
            computedCodigoSelecionado,
            change,
            servicoIntegracao,
        };
    },
});
