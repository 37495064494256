
import { computed, defineComponent, reactive } from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IConsultaMarketPlace } from '@/models/Integracoes/IConsultaMarketPlace';
import ServicoAnuncio from '@/servicos/ServicoAnuncio';

export default defineComponent({
    name: 'SelecionarCategoriaMarketPlace',
    props: {
        codigoIntegracao: {
            type: Number,
            default: 0,
        },
        identificadorSelecionado: {
            type: String || undefined,
        },
        nomeSelecionado: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Digite para selecionar',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        dataAttributeColuna: {
            type: String,
            default: '',
        },
        classCss: {
            type: String,
            default: '',
        },
        limparSelecao: {
            type: Boolean,
            default: true,
        },
    },
    components: {
    },
    emits: ['update:identificadorSelecionado', 'update:nomeSelecionado', 'change'],
    setup(props, { emit }) {
        let debounce = 0;
        const servicoAnuncio = new ServicoAnuncio();

        const state = reactive({
            buscandoDados: false,
            emDigitacao: false,
            listaOpcoes: [] as IConsultaMarketPlace[],
        });

        function verificacaoPreBusca(valor: any): boolean {
            if (state.emDigitacao) { return false; }
            if (!UtilitarioGeral.valorValido(valor)) { return false; }
            if (props.identificadorSelecionado === '') {
                return false;
            }

            if (state.listaOpcoes.some((c) => c.identificador === valor)) return false;

            return true;
        }

        function aguardarConclusaoCarregamento(computedIdentificadorSelecionado: string): boolean {
            return (!state.emDigitacao && !UtilitarioGeral.validaLista(state.listaOpcoes) && (UtilitarioGeral.validaCodigo(computedIdentificadorSelecionado)));
        }

        async function obterCategoriaPorIdentificador(valor: any) {
            if (!verificacaoPreBusca(valor)) return;
            console.log('vai buscar', valor);
            state.buscandoDados = true;
            state.listaOpcoes = await servicoAnuncio.consultaCategoria(props.codigoIntegracao, valor, true);
            console.log('buscou', valor);
            state.buscandoDados = false;
        }

        async function pesquisarCategoria(valorPesquisa: any) {
            state.emDigitacao = true;
            if (!UtilitarioGeral.valorValido(valorPesquisa)) {
                return;
            }

            if (valorPesquisa.length < 3) {
                return;
            }

            state.buscandoDados = true;
            state.listaOpcoes = [];

            clearTimeout(debounce);
            debounce = setTimeout(async () => {
                state.listaOpcoes = await servicoAnuncio.consultaCategoria(props.codigoIntegracao, valorPesquisa, false);
                state.buscandoDados = false;
            }, 600);
        }

        const computedNomeSelecionado = computed({
            get: () => props.nomeSelecionado,
            set: (val: string) => {
                emit('update:nomeSelecionado', val);
            },
        });

        function preencherNomeCategoria(identificadorCategoria: string) {
            const opcao = state.listaOpcoes.find((c) => c.identificador === identificadorCategoria);
            if (opcao !== undefined) {
                computedNomeSelecionado.value = opcao.valor;
            } else {
                computedNomeSelecionado.value = '';
            }
        }

        function obterCategoria(): any {
            if (!UtilitarioGeral.valorValido(props.identificadorSelecionado)) {
                return undefined;
            }
            obterCategoriaPorIdentificador(props.identificadorSelecionado);
            if (UtilitarioGeral.valorValido(props.identificadorSelecionado)) {
                preencherNomeCategoria(props.identificadorSelecionado || '');
            }
            return props.identificadorSelecionado;
        }

        const computedIdentificadorSelecionado = computed({
            get: () => obterCategoria(),
            set: (val: string) => {
                preencherNomeCategoria(val);
                emit('update:identificadorSelecionado', val);
            },
        });

        function change() {
            emit('change', props.identificadorSelecionado);
        }

        return {
            props,
            state,
            UtilitarioGeral,
            computedIdentificadorSelecionado,
            pesquisarCategoria,
            aguardarConclusaoCarregamento,
            change,
        };
    },
});
