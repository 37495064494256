import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_auto_complete, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      placeholder: _ctx.campo.placeholder,
      "allow-clear": true,
      onSearch: _ctx.pesquisarValor,
      options: _ctx.state.opcoes,
      class: "ss-tamanho-completo"
    }, null, 8, ["value", "placeholder", "onSearch", "options"])
  ]))
}