
import {
    defineComponent, computed,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ERegraQuantidadeAnunciada } from '@/models/Enumeradores/ERegraQuantidadeAnunciada';
import { EComponente } from '@/models/Enumeradores/EComponente';
import { ICampo } from '@/models/Integracoes/Campos/ICampo';
import AnuncioCampoAutoComplete from '@/components/Anuncios/AnuncioCampoAutoComplete.vue';
import AnuncioCampoSelecionar from '@/components/Anuncios/AnuncioCampoSelecionar.vue';

export default defineComponent({
    name: 'AnuncioCampos',
    props: {
        campos: {
            type: Array as () => ICampo[],
            required: true,
        },
        complementoTitulo: {
            type: String,
            default: '',
        },
    },
    components: {
        Icone,
        AnuncioCampoAutoComplete,
        AnuncioCampoSelecionar,
    },
    emits: ['update:campos'],
    setup(props, { emit }) {
        const computedCampos = computed({
            get: () => props.campos,
            set: (valor: ICampo[]) => {
                emit('update:campos', valor);
            },
        });

        function obtemClassCampo(campo: ICampo): string {
            let clasCss = '';
            if (!campo.visivel) {
                clasCss = ' ss-ocultar ';
            }
            if (campo.largura === 100) {
                clasCss += ' ant-col ant-col-xs-24 ant-col-xl-24 ';
            } else {
                clasCss += ' ant-col ant-col-xs-24 ant-col-xl-12 ';
            }
            return clasCss;
        }

        function validarInformacaoCampo(evento: any, informacao: any) {
            if (!/^[\d,.?!]+$/.test(informacao)) {
                evento.preventDefault();
            }
        }

        function validarInformacaoDigitada(evento: any) {
            const charCode = String.fromCharCode(evento.keyCode);
            validarInformacaoCampo(evento, charCode);
        }

        function validarInformacaoColada(evento: any) {
            const informacao = evento.clipboardData.getData('text');
            validarInformacaoCampo(evento, informacao);
        }

        return {
            props,
            computedCampos,
            UtilitarioGeral,
            ERegraQuantidadeAnunciada,
            EComponente,
            obtemClassCampo,
            validarInformacaoDigitada,
            validarInformacaoColada,
        };
    },
});
